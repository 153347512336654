import { useId } from "react"

type Props = { title?: string } & Pick<
  React.ComponentProps<'svg'>,
  'id' | 'className' | 'style' | 'tabIndex' | 'fill' | 'width' | 'height' |
  // aria props
  'role' | 'aria-hidden' | 'aria-label' | 'aria-labelledby' | 'aria-live' | 'aria-atomic' | 'aria-busy' | 'aria-activedescendant' | 'aria-autocomplete' | 'aria-braillelabel' | 'aria-brailleroledescription' | 'aria-checked' | 'aria-colcount' | 'aria-colindex' | 'aria-colspan' | 'aria-colindextext' | 'aria-controls' | 'aria-current' | 'aria-describedby' | 'aria-details' | 'aria-disabled' | 'aria-description' | 'aria-errormessage' | 'aria-expanded' | 'aria-flowto' | 'aria-grabbed' | 'aria-dropeffect' | 'aria-haspopup' | 'aria-invalid' | 'aria-keyshortcuts' | 'aria-level' | 'aria-modal' | 'aria-multiline' | 'aria-multiselectable' | 'aria-orientation' | 'aria-owns' | 'aria-placeholder' | 'aria-posinset' | 'aria-pressed' | 'aria-readonly' | 'aria-relevant' | 'aria-required' | 'aria-roledescription' | 'aria-rowcount' | 'aria-rowindex' | 'aria-rowindextext' | 'aria-rowspan' | 'aria-selected' | 'aria-setsize' | 'aria-sort' | 'aria-valuemax' | 'aria-valuemin' | 'aria-valuenow' | 'aria-valuetext'
>

export const CoordinateIcon: React.FC<Props> = ({title,...props}) => {
const id = useId()
return(
<svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-describedby={title ? id : undefined} {...props}>
{title && <title id={id}>{title}</title>}
<path d="M16.68 5.37012V4.41012C16.68 3.42012 15.85 2.62012 14.84 2.62012H9.14996C8.12996 2.62012 7.30996 3.42012 7.30996 4.41012V5.37012H5.41996C3.64996 5.37012 2.20996 6.82012 2.20996 8.61012V18.2501C2.20996 20.0401 3.64996 21.4901 5.41996 21.4901H18.57C20.34 21.4901 21.78 20.0401 21.78 18.2501V8.61012C21.78 6.82012 20.34 5.37012 18.57 5.37012H16.68ZM9.02996 4.41012C9.02996 4.41012 9.06996 4.35012 9.13996 4.35012H14.83C14.9 4.35012 14.94 4.39012 14.94 4.41012V5.37012H9.02996V4.41012ZM20.04 18.2501C20.04 19.0801 19.38 19.7601 18.56 19.7601H5.40996C4.59996 19.7601 3.92996 19.0801 3.92996 18.2501V8.61012C3.92996 7.78012 4.58996 7.10012 5.40996 7.10012H18.56C19.38 7.10012 20.04 7.78012 20.04 8.61012V18.2501Z" />
<path d="M11.9802 9.87012C10.0102 9.87012 8.41016 11.4701 8.41016 13.4401C8.41016 15.4101 10.0102 17.0101 11.9802 17.0101C13.9502 17.0101 15.5502 15.4101 15.5502 13.4401C15.5502 11.4701 13.9502 9.87012 11.9802 9.87012ZM11.9802 15.2701C10.9702 15.2701 10.1402 14.4501 10.1402 13.4301C10.1402 12.4101 10.9602 11.5901 11.9802 11.5901C13.0002 11.5901 13.8202 12.4101 13.8202 13.4301C13.8202 14.4501 13.0002 15.2701 11.9802 15.2701Z" />
<path d="M17.3596 11.1792C18.0555 11.1792 18.6196 10.6151 18.6196 9.91918C18.6196 9.2233 18.0555 8.65918 17.3596 8.65918C16.6637 8.65918 16.0996 9.2233 16.0996 9.91918C16.0996 10.6151 16.6637 11.1792 17.3596 11.1792Z" />
</svg>
)
}
