import { useId } from "react"

type Props = { title?: string } & Pick<
  React.ComponentProps<'svg'>,
  'id' | 'className' | 'style' | 'tabIndex' | 'fill' | 'width' | 'height' |
  // aria props
  'role' | 'aria-hidden' | 'aria-label' | 'aria-labelledby' | 'aria-live' | 'aria-atomic' | 'aria-busy' | 'aria-activedescendant' | 'aria-autocomplete' | 'aria-braillelabel' | 'aria-brailleroledescription' | 'aria-checked' | 'aria-colcount' | 'aria-colindex' | 'aria-colspan' | 'aria-colindextext' | 'aria-controls' | 'aria-current' | 'aria-describedby' | 'aria-details' | 'aria-disabled' | 'aria-description' | 'aria-errormessage' | 'aria-expanded' | 'aria-flowto' | 'aria-grabbed' | 'aria-dropeffect' | 'aria-haspopup' | 'aria-invalid' | 'aria-keyshortcuts' | 'aria-level' | 'aria-modal' | 'aria-multiline' | 'aria-multiselectable' | 'aria-orientation' | 'aria-owns' | 'aria-placeholder' | 'aria-posinset' | 'aria-pressed' | 'aria-readonly' | 'aria-relevant' | 'aria-required' | 'aria-roledescription' | 'aria-rowcount' | 'aria-rowindex' | 'aria-rowindextext' | 'aria-rowspan' | 'aria-selected' | 'aria-setsize' | 'aria-sort' | 'aria-valuemax' | 'aria-valuemin' | 'aria-valuenow' | 'aria-valuetext'
>

export const CartIcon: React.FC<Props> = ({title,...props}) => {
const id = useId()
return(
<svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-describedby={title ? id : undefined} {...props}>
{title && <title id={id}>{title}</title>}
<path d="M22 6.09877H5.93227L5.05332 3H1V4.91283H3.6072L6.48216 15.0632C6.53898 15.2634 6.65959 15.4397 6.82563 15.5652C6.99168 15.6907 7.19413 15.7585 7.40226 15.7585H18.2882C18.4917 15.7585 18.6899 15.6936 18.854 15.5732C19.0181 15.4528 19.1395 15.2832 19.2006 15.0891L22 6.09877ZM6.47358 8.0116H19.4072L17.59 13.8457H8.1215L6.47358 8.0116Z" />
<path d="M7.28174 18.0818C6.92771 18.082 6.58173 18.1872 6.28744 18.384C5.99316 18.5808 5.76384 18.8604 5.62845 19.1875C5.49306 19.5146 5.45765 19.8745 5.52676 20.2218C5.59587 20.569 5.76636 20.8879 6.0167 21.1382C6.26703 21.3886 6.58597 21.5591 6.93318 21.6282C7.2804 21.6973 7.6403 21.6619 7.96741 21.5265C8.29453 21.3912 8.57419 21.1618 8.771 20.8676C8.9678 20.5733 9.07296 20.2272 9.07315 19.8732C9.07315 19.3981 8.88442 18.9425 8.54848 18.6065C8.21253 18.2706 7.75684 18.0818 7.28174 18.0818Z" />
<path d="M17.8148 18.0818C17.4607 18.082 17.1147 18.1872 16.8205 18.384C16.5262 18.5808 16.2969 18.8604 16.1615 19.1875C16.0261 19.5146 15.9907 19.8745 16.0598 20.2218C16.1289 20.569 16.2994 20.8879 16.5497 21.1382C16.8001 21.3886 17.119 21.5591 17.4662 21.6282C17.8134 21.6973 18.1733 21.6619 18.5004 21.5265C18.8275 21.3912 19.1072 21.1618 19.304 20.8676C19.5008 20.5733 19.606 20.2272 19.6062 19.8732C19.6062 19.3981 19.4174 18.9425 19.0815 18.6065C18.7455 18.2706 18.2899 18.0818 17.8148 18.0818Z" />
</svg>
)
}
