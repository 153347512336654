import { useId } from "react"

type Props = { title?: string } & Pick<
  React.ComponentProps<'svg'>,
  'id' | 'className' | 'style' | 'tabIndex' | 'fill' | 'width' | 'height' |
  // aria props
  'role' | 'aria-hidden' | 'aria-label' | 'aria-labelledby' | 'aria-live' | 'aria-atomic' | 'aria-busy' | 'aria-activedescendant' | 'aria-autocomplete' | 'aria-braillelabel' | 'aria-brailleroledescription' | 'aria-checked' | 'aria-colcount' | 'aria-colindex' | 'aria-colspan' | 'aria-colindextext' | 'aria-controls' | 'aria-current' | 'aria-describedby' | 'aria-details' | 'aria-disabled' | 'aria-description' | 'aria-errormessage' | 'aria-expanded' | 'aria-flowto' | 'aria-grabbed' | 'aria-dropeffect' | 'aria-haspopup' | 'aria-invalid' | 'aria-keyshortcuts' | 'aria-level' | 'aria-modal' | 'aria-multiline' | 'aria-multiselectable' | 'aria-orientation' | 'aria-owns' | 'aria-placeholder' | 'aria-posinset' | 'aria-pressed' | 'aria-readonly' | 'aria-relevant' | 'aria-required' | 'aria-roledescription' | 'aria-rowcount' | 'aria-rowindex' | 'aria-rowindextext' | 'aria-rowspan' | 'aria-selected' | 'aria-setsize' | 'aria-sort' | 'aria-valuemax' | 'aria-valuemin' | 'aria-valuenow' | 'aria-valuetext'
>

export const RankingBadgeIcon: React.FC<Props> = ({title,...props}) => {
const id = useId()
return(
<svg viewBox="0 0 28 28" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-describedby={title ? id : undefined} {...props}>
{title && <title id={id}>{title}</title>}
<path fillRule="evenodd" clipRule="evenodd" d="M28 0V28L14 23.4108L0 28V0L28 0Z" />
</svg>
)
}
