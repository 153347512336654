type Props = {
  openAppModal: () => void
}

export const ContentTabMenu = ({ openAppModal }: Props) => {
  const tabClasses =
    'block w-[139px] bg-white py-[5px] text-center text-[13px] text-gray-500 hover:bg-gray-50 aria-selected:bg-black-400 aria-selected:text-white'
  return (
    <ul
      className="flex w-fit divide-x divide-gray-300 overflow-hidden rounded-[8px] border border-gray-300"
      role="tablist"
    >
      <li role="presentation">
        <a
          href="/"
          role="tab"
          aria-selected
          tabIndex={-1}
          className={tabClasses}
        >
          おすすめ
        </a>
      </li>
      <li role="presentation">
        <button
          onClick={openAppModal}
          type="button"
          role="tab"
          aria-selected={false}
          tabIndex={0}
          className={tabClasses}
        >
          タイムライン
        </button>
      </li>
    </ul>
  )
}
