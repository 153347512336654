import { StaticImageData } from 'next/image'
import { CloseButton } from '~/components/elements/CloseButton'
import { Divider } from '~/components/elements/Divider'
import app_download_for_coordinatedetail from '~/images/adjust_qr_code/app_download_for_coordinatedetail.webp'
import app_download_for_coordinatelist from '~/images/adjust_qr_code/app_download_for_coordinatelist.webp'
import app_download_for_itemdetail from '~/images/adjust_qr_code/app_download_for_itemdetail.webp'
import app_download_for_itemlist from '~/images/adjust_qr_code/app_download_for_itemlist.webp'
import app_download_for_memberdetail from '~/images/adjust_qr_code/app_download_for_memberdetail.webp'
import app_download_for_mypage from '~/images/adjust_qr_code/app_download_for_mypage.webp'
import app_download_for_top from '~/images/adjust_qr_code/app_download_for_top.webp'
import app_download_for_videodetail from '~/images/adjust_qr_code/app_download_for_videodetail.webp'
import { Img } from '../elements/Img'
import WearAppMock from './wear_app_mock.webp'

// 'coordinateDetail' | 'itemDetail' | 'others'のように増やしていく
type ImageType =
  | 'coordinateDetail'
  | 'itemDetail'
  | 'itemList'
  | 'coordinateList'
  | 'shopList'
  | 'mypage'
  | 'videoDetail'
  | 'memberDetail'
  | 'top'
const images: Record<ImageType, StaticImageData> = {
  coordinateDetail: app_download_for_coordinatedetail,
  itemDetail: app_download_for_itemdetail,
  itemList: app_download_for_itemlist,
  coordinateList: app_download_for_coordinatelist,
  // TODO: ショップ一覧ページをリプレイス時に読み込む画像差し替える
  shopList: app_download_for_itemlist,
  mypage: app_download_for_mypage,
  videoDetail: app_download_for_videodetail,
  memberDetail: app_download_for_memberdetail,
  top: app_download_for_top,
}
type Props = {
  close?: () => void
  text: string
  imageType: ImageType
}

export const AppDownloadDialog: React.FC<Props> = ({
  close,
  text,
  imageType,
}) => (
  <div className="relative flex h-[430px] w-[690px] items-center rounded-[16px] bg-white">
    <CloseButton
      className="absolute right-[17px] top-1 p-[6px] text-[24px] text-gray-500 xl:hover:opacity-70"
      onClick={close}
    />
    <div className="shrink-0 pb-[10px] pl-[70px] pr-[34px]">
      <Img src={WearAppMock} alt="WEARアプリ" height={300} />
    </div>
    <Divider className="!h-[263px] w-[1px]" color="bg-gray-200" />
    <div className="flex grow flex-col items-center pb-[3px] pr-[38px] text-center font-bold">
      <p className="whitespace-pre-line text-[16px] leading-normal">{text}</p>
      <p className="pt-4">
        <Img
          src={images[imageType]}
          width={124}
          height={124}
          alt="アプリダウンロード用QRコード"
        />
      </p>
      <p className="pt-[14px]">
        <a
          className="block text-[13px] leading-none text-blue-400 hover:opacity-70"
          href="/first/"
        >
          アプリについて
        </a>
      </p>
    </div>
  </div>
)
