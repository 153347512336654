import dynamic from 'next/dynamic'
import { useCallback, useState } from 'react'
import { Schemas } from '~/api/gen'
import { Divider } from '~/components/elements/Divider'
import { AppDownloadDialog } from '~/components/modules/AppDownloadDialogForPc'
import { useModal } from '~/hooks/useModal'
import { Gender } from '~/lib/gender'
import { onAnchorLinkClickSmoothScroll } from '~/lib/onAnchorLinkClickSmoothScroll'
import { ScreenIds } from '~/types/screenIds'
import { buildRankingUrl } from '../CoordinateRanking/lib/buildRankingUrl'
import { useAbstractUrl } from '../shared/abstractUrl/hooks/useAbstractUrl'
import { setAffiliateObjectCookie } from '../shared/affiliate/lib/setAffiliateObjectCookie'
import { useBanner } from '../shared/banner/hooks/useBanner'
import { buildUrl } from '../shared/search/coordinate'
import { CarouselBanners } from './components/CarouselBanners'
import { ContentTabMenu } from './components/ContentTabMenu'
import { CoordinateCard } from './components/CoordinateCard'
import { GenderTabMenu } from './components/GenderTabMenu'
import { PopularTopicCard } from './components/PopularTopicCard'
import { SectionContainer } from './components/SectionContainer'
import { SideMenu } from './components/SideMenu'
import { convertToCoordinateCardProps } from './components/lib/convertToCoordinateCardProps'
import { pageBottomPcAds } from './data/googleAd'

const GoogleAd = dynamic(
  () => import('~/features/shared/ad/components/GoogleAd'),
  {
    ssr: false,
  }
)

const ThinBanner = dynamic(
  () => import('~/features/shared/banner/PC/ThinBanner'),
  { ssr: false }
)

type Props = {
  gender?: Gender
  updatedHour: number
  recommendedMembers: Schemas.official_recommended_member_with_member[]
  brands: Schemas.brand_with_brand_aggregate[]
  trendCoordinateTags: Schemas.trend_coordinate_tag[]
  recommendedCategories: Schemas.official_recommended_category_with_category[]
  pickupTags: Schemas.pickup_tag_schedule_with_coordinates['tag'][]
  coordinateRanking: Schemas.coordinate_hourly_ranking_with_coordinate[]
  recommendedCoordinates: Schemas.coordinate_small_with_member_small[]
  popularFolders: Schemas.folder_collection_with_folder_elements[]
  coordinateItems: Schemas.coordinate_item_with_coordinate_id[]
}

const setAffiliateObject = (coordinateId: number) =>
  setAffiliateObjectCookie({
    screenId: ScreenIds.Top,
    objectId: coordinateId,
  })

export const PcTopPage: React.FC<Props> = ({
  gender,
  updatedHour,
  recommendedMembers,
  brands,
  trendCoordinateTags,
  recommendedCategories,
  pickupTags,
  coordinateRanking,
  recommendedCoordinates,
  popularFolders,
  coordinateItems,
}) => {
  const [hideCoordinateItem, setHideCoordinateItem] = useState(false)
  const toggleCoordinateItemVisibility = useCallback(() => {
    setHideCoordinateItem((prev) => !prev)
  }, [])
  const abstractUrl = useAbstractUrl()
  const { banners: noticeBanners, onClickBanner: onClickNoticeBanner } =
    useBanner('web_info', abstractUrl)
  const { banners: niauBanners, onClickBanner: onClickNiauBanner } = useBanner(
    'pc_left_menu',
    abstractUrl
  )

  const [PcAppModal, openPcAppModal, closePcAppModal, isPcAppModalOpen] =
    useModal(200)

  return (
    <div>
      <ThinBanner bannerType="pc_gbl_ad_top" />
      <GenderTabMenu current={gender} />
      <div className="mx-auto flex max-w-[990px] flex-col gap-[30px] pt-[23px]">
        <div className="relative flex w-full justify-center">
          <ContentTabMenu openAppModal={openPcAppModal} />
          {noticeBanners.length > 0 && (
            <a
              href="#notice"
              className="absolute right-0 top-1/2 -translate-y-1/2 text-[13px] text-gray-600 hover:underline"
              onClick={onAnchorLinkClickSmoothScroll}
            >
              WEARからのお知らせ
            </a>
          )}
        </div>
        <div className="grid grid-cols-[180px_1fr] gap-[30px]">
          <SideMenu
            gender={gender}
            members={recommendedMembers.map((member) => {
              return {
                href: `/${member.member.user_name}/`,
                image: member.member.image.url_120,
                nickname: member.member.nick_name,
                followersCount: member.member.member_aggregate.followers_count,
                is_salon_clerk: member.member.is_salon_clerk,
                is_shop_clerk: member.member.is_shop_clerk,
                is_sponsored: member.member.is_sponsored,
                is_wearista: member.member.is_wearista,
                is_awarded: member.member.is_awarded,
              }
            })}
            brands={brands
              .map((brand) => {
                if (
                  !brand ||
                  !brand.name ||
                  !brand.id ||
                  !brand.key ||
                  !brand.name_kana
                )
                  return undefined
                return {
                  href: buildUrl({
                    snapItems: [
                      {
                        brand: {
                          id: brand.id,
                          key: brand.key,
                          name: brand.name,
                        },
                      },
                    ],
                  }),
                  brandName: brand.name,
                  brandNameKana: brand.name_kana,
                }
              })
              .filter((brand) => brand !== undefined)}
            trendKeywords={trendCoordinateTags.map((tag) => {
              return {
                href: buildUrl({
                  searchWord: tag.word,
                }),
                keyword: tag.word,
              }
            })}
            popularCategories={recommendedCategories
              .map((category) => {
                if (!category.item_category?.keyword) return
                return {
                  href: buildUrl({
                    snapItems: [
                      {
                        category: {
                          id: category.item_category.id,
                          key: category.item_category.keyword,
                          name: category.item_category.name,
                          child: category.item_child_category?.keyword
                            ? {
                                id: category.item_child_category.id,
                                key: category.item_child_category.keyword,
                                name: category.item_child_category.name,
                              }
                            : undefined,
                        },
                      },
                    ],
                  }),
                  categoryName: category.title,
                }
              })
              .filter((category) => category !== undefined)}
            notice={{
              banners: noticeBanners,
              onClickBanner: onClickNoticeBanner,
            }}
            niau={{ banners: niauBanners, onClickBanner: onClickNiauBanner }}
          />
          <div className="flex flex-col gap-8 overflow-x-auto">
            {pickupTags.map((tag) => {
              if (tag.coordinates.length === 0) return null

              return (
                <SectionContainer
                  key={tag.id}
                  title={`#${tag.name}`}
                  href={buildUrl({ gender, tagIds: [tag.id] })}
                >
                  <ul className="grid grid-cols-3 gap-[18px]">
                    {tag.coordinates.map((coordinate) => (
                      <li key={coordinate.id}>
                        <CoordinateCard
                          {...convertToCoordinateCardProps({
                            coordinate,
                            hideCoordinateItem,
                            onCoordinateItemVisibleToggleButtonClick:
                              toggleCoordinateItemVisibility,
                            coordinateItems,
                          })}
                          imagePriority
                          onItemClick={() => setAffiliateObject(coordinate.id)}
                        />
                      </li>
                    ))}
                  </ul>
                </SectionContainer>
              )
            })}
            <SectionContainer
              title="ランキング"
              subtitle={`${updatedHour}:00 更新`}
              href={buildRankingUrl(gender ?? 'all')}
            >
              <ol className="grid grid-cols-3 gap-[18px]">
                {coordinateRanking.map((ranking) => (
                  <li key={ranking.coordinate.id}>
                    <CoordinateCard
                      {...convertToCoordinateCardProps({
                        coordinate: ranking.coordinate,
                        rank: ranking.order,
                        hideCoordinateItem,
                        onCoordinateItemVisibleToggleButtonClick:
                          toggleCoordinateItemVisibility,
                        coordinateItems,
                      })}
                      onItemClick={() =>
                        setAffiliateObject(ranking.coordinate.id)
                      }
                    />
                  </li>
                ))}
              </ol>
            </SectionContainer>
            <SectionContainer
              title="おすすめのコーディネート"
              href={buildUrl({ gender })}
            >
              <ul className="grid grid-cols-6 gap-[18px]">
                {recommendedCoordinates.map((coordinate, index) => {
                  const colSpanClass =
                    index % 8 === 0 || index % 8 === 1
                      ? 'col-span-3'
                      : 'col-span-2'

                  return (
                    <li key={coordinate.id} className={colSpanClass}>
                      <CoordinateCard
                        {...convertToCoordinateCardProps({
                          coordinate: coordinate,
                          hideCoordinateItem,
                          onCoordinateItemVisibleToggleButtonClick:
                            toggleCoordinateItemVisibility,
                          coordinateItems,
                        })}
                        onItemClick={() => setAffiliateObject(coordinate.id)}
                      />
                    </li>
                  )
                })}
              </ul>
            </SectionContainer>
            <SectionContainer title="人気のトピック">
              <ul className="flex gap-[18px]">
                {popularFolders.map((folder) => (
                  <PopularTopicCard
                    key={folder.id}
                    title={folder.name}
                    href={`/${folder.member.user_name}/favorite/${folder.id}/`}
                    imgUrls={folder.folder_elements.map(
                      (element) => element.image?.url_125 ?? ''
                    )}
                  />
                ))}
              </ul>
            </SectionContainer>
            <div className="hidden justify-center gap-x-[46px] pb-[96px] pt-10 xl:flex">
              {pageBottomPcAds.map((ad) => (
                <GoogleAd key={ad} elementId={ad} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="flex justify-center pb-[6px] pt-8">
        <CarouselBanners />
      </div>
      <PcAppModal
        wrapperClassName={`ease-linear duration-200 transition-all ${
          isPcAppModalOpen ? 'opacity-100' : 'opacity-0'
        }`}
        overlayClassName="bg-black/60"
      >
        <AppDownloadDialog
          close={closePcAppModal}
          text={'フォローユーザーの\n新着投稿はアプリから\n見ることができます'}
          imageType="top"
        />
      </PcAppModal>
    </div>
  )
}
