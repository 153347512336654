import { startOfTomorrow } from 'date-fns'
import { setCookie } from '~/lib/cookieUtil'
import { AffiliateObject } from './types'

export const setAffiliateObjectCookie = (object: AffiliateObject) => {
  // クッキーは当日中保持
  const expires = startOfTomorrow()
  setCookie('AfScreenID', object.screenId.toString(), expires)
  if (object.objectId)
    setCookie('AfObjectID', object.objectId.toString(), expires)
}
