/**
 * アンカーリンクをクリックした際にアニメーションつきでスクロールさせる
 * @example
 * ```jsx
 * <a href="#section" onClick={onAnchorLinkClickSmoothScroll}>...</a>
 * ```
 */
export const onAnchorLinkClickSmoothScroll = (
  e: React.MouseEvent<HTMLAnchorElement>
) => {
  try {
    const anchor = new URL(e.currentTarget.href).hash.slice(1)
    if (!anchor) return
    // 日本語はエンコードされるので見つからない場合はデコードして再検索
    const target =
      document.getElementById(anchor) ||
      document.getElementById(decodeURIComponent(anchor))
    if (!target) return
    target.scrollIntoView({ behavior: 'smooth' })
    e.preventDefault()
    window.history.replaceState(null, '', `#${anchor}`)
  } catch {
    // noop
  }
}
